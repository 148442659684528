import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const EvenFinancialWidget = ({ url, slug }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const script = document.createElement('script');
            script.src = 'https://embed.hifiona.com/script/helper/bundle.js';
            script.async = true;
            script.addEventListener('load', () => setLoaded(true));
            document.body.appendChild(script);
        }
    }, []);

    useEffect(() => {
        if(!loaded || !url) return;

        if (typeof window !== 'undefined') {
            window.document.getElementsByClassName("even-iframe")[0].src =
                window.document.getElementsByClassName("even-iframe")[0].src;
        }
    }, [loaded]);

    if (!loaded || !url) {
        return null;
    }
    const finalURL = `${url}&tag.source=${slug}`;
    return (
        <div>
            <iframe
                title="even-financial"
                className="even-iframe"
                id="iFrameResizer0"
                scrolling="no"
                src={finalURL}
                style={{ width: '100%', border: 'none' }}
            />
        </div>
    );
};

EvenFinancialWidget.propTypes = {
    url: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
};


export default EvenFinancialWidget;
